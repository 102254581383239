import classNames from 'classnames';

import { useMainMachine } from '../../../utils/useMainMachine';
import { DeviceSwitcher } from './DeviceSwitcher';
import { Generate } from './Generate';
import { UndoRedo } from './UndoRedo';
import { DEVICES } from '../../constants';
import { SaveButton } from './SaveButton';
import { VoiceSelector } from './VoiceSelector';
import { WordsChanged } from './WordsChanged';
import { InteractiveModeButton } from './InteractiveModeButton';

export function TopBar() {
  const { useSelector } = useMainMachine();
  const device = useSelector(({ context }) => context.device);

  const isMobile = device === DEVICES.MOBILE;

  return (
    <div className="contents" data-testid="topbar">
      <div
        className={classNames('h-[70px] shrink-0 grow-0 flex pt-[30px] pb-[10px] items-center w-full', {
          'justify-between': !isMobile,
          'justify-center space-x-10': isMobile,
        })}
      >
        {!isMobile ? (
          <div className="w-full grow mr-5 flex items-center space-x-2.5">
            <DeviceSwitcher />
            <InteractiveModeButton />
          </div>
        ) : null}
        <div
          className={classNames('flex items-center space-x-2.5', {
            'w-full grow': !isMobile,
          })}
        >
          <VoiceSelector />
          <Generate />
        </div>
        <div
          className={classNames('flex items-center justify-end ml-5', {
            'w-full grow': !isMobile,
          })}
        >
          <UndoRedo />
          <WordsChanged />
          <SaveButton />
        </div>
      </div>
      {isMobile ? (
        <div className="h-[45px] pt-1.25 pb-2.5 flex justify-center w-full">
          <DeviceSwitcher />
          <InteractiveModeButton className="ml-2.5" />
        </div>
      ) : null}
    </div>
  );
}
