import { useMainMachine } from '../../../utils/useMainMachine';
import { Button } from '../basic-ui/Button';
import { ACTIONS } from '../../../state';
import { BUTTON_TYPES } from '../../constants';

import InteractiveIcon from '@crazyegginc/hatch/dist/images/icon-interactive-outline.svg?react';

export function InteractiveModeButton({ className }) {
  const { machine, useSelector } = useMainMachine();
  const interactiveMode = useSelector(({ context }) => context.interactiveMode);

  return (
    <Button
      onClick={() => machine.send({ type: ACTIONS.TOGGLE_INTERACTIVE })}
      variant={interactiveMode ? BUTTON_TYPES.PRIMARY : BUTTON_TYPES.SECONDARY}
      className={className}
    >
      <InteractiveIcon className="w-4 h-4 fill-current" aria-label="toggle interactive mode" />
    </Button>
  );
}
